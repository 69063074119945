import baseAxios from '@/api/base';

// baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;

/** HomeNotify api */
/**
 *  取得畫面提醒
 * @param {object} data {  }
 */
export default () => baseAxios.post('/FrontEnd/HomeNotify/HomeNotifys');

// export default GetHomeNotifys;
