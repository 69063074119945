import baseAxios from '@/api/base';

// baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;

/*
 *  前台 取得(圖片輪播)
 */
const GetHomeBanner = () => baseAxios.post('/FrontEnd/HomeBanner/HomeBanner');

export default GetHomeBanner;
