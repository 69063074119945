<template>
  <!-- 精選課程 -->
  <section v-if="data.length > 0" class="hot pt-6" data-aos="zoom-in-up">
    <div class="container">
      <h2 class="cs-title my-3 my-md-6" data-style="1">
        <span data-title="自選課程 Optional Course" v-if="this.courseType === 2"></span>
        <span data-title="精選課程 Hot Course" v-else></span>
      </h2>
      <div class="swiper-container" :id="this.courseType === 2 ? 'option':'hot'">
        <ul class="swiper-wrapper row flex-nowrap flex-md-wrap px-md-6 swiper-no-swiping">
          <li class="swiper-slide col-md-4" v-for="item in data" :key="item.courseId">
            <router-link
              class="hot__card card card-shadow text-decoration-none mb-6"
              :to="`/course/classRoom/${item.courseId}`"
            >
              <div class="hot__img bg-outer" :class="{ hot__img__star: UseStar() }">
                <div
                  class="bg-image"
                  :style="{
                    backgroundImage: 'url(' + item.mainImagePath + ')',
                  }"
                ></div>
              </div>
              <h5 class="hot__title">{{ item.examName }}</h5>
              <div class="card-body hot__content">
                <h5 class="card-title font-weight-bold">{{ item.courseName }}</h5>
                <p class="card-text">
                  {{ item.courseContent }}
                </p>
              </div>
            </router-link>
          </li>
        </ul>
        <!-- Add Arrows -->
        <div class="swiper-button-next d-md-none"></div>
        <div class="swiper-button-prev d-md-none"></div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper, { Navigation, Pagination } from 'swiper';
import { GetBuyCourseSearchByWonderful } from '@/api/BuyCourseApi';

Swiper.use([Navigation, Pagination]);

export default {
  props: ['courseType'],
  data() {
    return {
      data: [],
    };
  },
  created() {
  },
  async mounted() {
    const id = this.courseType === 2 ? '#option' : '#hot';
    await this.GetList();
    // eslint-disable-next-line no-new
    new Swiper(id, {
      spaceBetween: 0,
      centeredSlides: true,
      loop: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        320: {
          // 当屏幕宽度大于等于320
          noSwiping: false,
          autoplay: true,
        },
        980: {
          // 当屏幕宽度大于等于1280
          noSwiping: true,
          autoplay: false,
        },
      },
    }); // video madal
  },
  methods: {
    GetList() {
      return GetBuyCourseSearchByWonderful({ courseType: this.courseType }).then((response) => {
        if (response.data.statusCode === 0) {
          this.data = response.data.data;
        }
      });
    },
    UseStar() {
      return this.courseType === 2;
    },
  },
};
</script>

<style></style>
