<template>
  <section>
    <!-- 輪播 -->
    <section class="">
      <!-- Swiper 套件 -->
      <div class="swiper-container w-100" id="banner">
        <div class="swiper-wrapper">
          <div class="swiper-slide swiper__slide" v-for="item in data" :key="item.homeLinkId">
            <div class="bg-outer" v-html="item.homeLinkUrl"></div>
          </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
      </div>
    </section>
  </section>
</template>

<script>
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import GetHomeBanner from '@/api/BannerApi';

Swiper.use([Navigation, Pagination, Autoplay]);
export default {
  data() {
    return {
      data: [],
    };
  },
  async mounted() {
    await this.GetList();

    // eslint-disable-next-line no-new
    new Swiper('#banner', {
      spaceBetween: 30,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  },
  methods: {
    GetList() {
      return GetHomeBanner().then((response) => {
        if (response.data.statusCode === 0) {
          this.data = response.data.data;
          this.data.forEach((item) => {
            const str = `<div
                  class="swiper__content bg-image d-md-none"
                  style="background-image:url(${item.homeLinkImgMobilePath})"></div>
                <div
                  class="swiper__content bg-image d-none d-md-block"
                  style="background-image:url(${item.homeLinkImgPath})"></div>`;
            // eslint-disable-next-line no-param-reassign
            if (item.homeLinkUrl === '') item.homeLinkUrl = str;
            else {
              // eslint-disable-next-line no-param-reassign
              item.homeLinkUrl = `<a href="${item.homeLinkUrl}" target="_blank" >${str}</a>`;
            }
            return item;
          });
        }
      });
    },
  },
};
</script>

<style></style>
