<template>
  <div>
    <!-- 網頁介紹文宣 -->
    <article class="banner d-none d-md-flex">
      <div class=" container-fluid container-lg h-100">
        <div class="d-flex align-items-center py-md-5 h-100">
          <div class="w-100">
            <h4 class="banner--title font-weight-bold mb-md-2">
              臺北市私立讀家法律商業技藝文理短期補習班
            </h4>
            <div class="d-flex justify-content-between align-items-end w-100">
              <p class="banner--description mb-0">
                專辦 司法官‧律師‧司法特考‧高普考‧法研所‧轉學考‧各類證照‧國家考試
              </p>
              <p class="small text-right">北市教終字第10730162700號</p>
            </div>
          </div>
        </div>
      </div>
    </article>

    <!-- 輪播 -->
    <BannerComponent />
    <!-- 最新消息 -->
    <NewsComponent />
    <!-- 精選課程 -->
    <HotClassComponent :course-type="courseTypeHot" />
    <!-- 自選課程 -->
    <HotClassComponent :course-type="courseTypeOpt" />
    <!-- 試聽課程 -->
    <VideoComponent />

    <!-- msg -->
    <el-dialog
      id="MsgInfo"
      v-if="data.length > 0"
      :title="data[notifyIndex].homeNotifyName"
      :visible.sync="DialogTableVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :destroy-on-close="true"
      :append-to-body="true"
      width="70%"
    >
      <div class="row" v-html="data[notifyIndex].homeNotifyContent"></div>
      <div
        slot="footer"
        class="dialog-footer"
        style="display: flex; flex-wrap: wrap; justify-content: space-between;"
      >
        <el-checkbox
          v-model="checked"
          style="flex-basis: 100%;text-align: left;"
          @change="SetNotShow"
          >今天不再顯示?</el-checkbox
        >
        <button
          :style="{ visibility: Visibility }"
          type="button"
          class="btn btn-default"
          @click="notifyIndex -= 1"
        >
          上一筆
        </button>

        <button
          v-if="notifyIndex < data.length - 1"
          type="button"
          class="btn btn-primary"
          @click="notifyIndex += 1"
        >
          下一筆
        </button>
        <button v-else type="button" class="btn btn-primary" @click="DialogTableVisible = false">
          關閉
        </button>
      </div>
    </el-dialog>
    <div :class="['text-center', 'cookiealert', { show: cookiealert === 'true' }]">
      <div>
        為了擁有最佳化瀏覽網站體驗，本站將自動將您的Cookie加入您的瀏覽器。
        <button type="button" class="btn btn-light btn-sm accept " @click="CoolieAlertNotShow">
          我接受
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BannerComponent from '@/components/index/BannerComponent.vue';
import VideoComponent from '@/components/index/VideoComponent.vue';
import HotClassComponent from '@/components/index/HotClassComponent.vue';
import NewsComponent from '@/components/index/NewsComponent.vue';
import GetHomeNotifys from '@/api/HomeNotifyApi';
import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

export default {
  components: {
    BannerComponent,
    VideoComponent,
    HotClassComponent,
    NewsComponent,
  },
  data() {
    return {
      courseTypeHot: 1,
      courseTypeOpt: 2,
      cookiealert: false,
      checked: false,
      DialogTableVisible: false,
      notifyIndex: 0,
      notify: {
        homeNotifyName: '123',
        homeNotifyContent: '456',
      },
      data: [
        {
          homeNotifyName: '123',
          homeNotifyContent: '456',
        },
      ],
    };
  },
  created() {
    const myStorage = window.localStorage;
    // eslint-disable-next-line operator-linebreak
    let todayNotShow =
      myStorage.getItem('TodayNotShow') !== null ? myStorage.getItem('TodayNotShow') : '';
    const start = this.$moment();
    const end = this.$moment(todayNotShow);
    if (start.diff(end) > 0) {
      todayNotShow = '';
      myStorage.removeItem('TodayNotShow');
    }
    if (todayNotShow === '') {
      this.GetHomeNotifys();
    }
    // eslint-disable-next-line operator-linebreak
    this.cookiealert =
      myStorage.getItem('CookieNotShow') !== null ? myStorage.getItem('CookieNotShow') : 'true';
  },
  computed: {
    Visibility() {
      if (this.notifyIndex > 0) return '';
      return 'hidden';
    },
  },
  methods: {
    GetHomeNotifys() {
      GetHomeNotifys().then((response) => {
        if (response.data.statusCode === 0) {
          this.data = response.data.data;
          if (this.data.length > 0) {
            this.data.forEach((item) => {
              let img = '';
              let classStr = 'col';

              if (item.homeNotifyImgPath) {
                img = `<div class="col-12 col-md-5 text-center">
                        <img style="width: 100%; max-width: 350px;" src=${item.homeNotifyImgPath} />
                      </div>`;
                classStr = 'col-12 col-md-6';
              }

              // eslint-disable-next-line no-param-reassign
              item.homeNotifyContent = `${img}
                <div class="${classStr}" style="font-size: 1.25em;">
                  <div style="white-space: pre-wrap;">${item.homeNotifyContent}</div>
                </div>`;

              return item;
            });
            this.DialogTableVisible = true;
          }
        }
      });
    },
    SetNotShow() {
      const myStorage = window.localStorage;
      if (this.checked === true) {
        myStorage.setItem(
          'TodayNotShow',
          this.$options.filters.toTaipei(this.$moment().endOf('day')),
        );
      } else myStorage.removeItem('TodayNotShow');
    },
    CoolieAlertNotShow() {
      const myStorage = window.localStorage;
      this.cookiealert = 'false';
      myStorage.setItem('CookieNotShow', this.cookiealert);
    },
  },
};
</script>

<style>
#MsgInfo .el-dialog .el-dialog__body {
  max-height: 500px;
  overflow: auto;
}
@media (max-height: 800px) {
  #MsgInfo .el-dialog .el-dialog__body {
    max-height: 300px;
    overflow: auto;
  }
}
</style>
