<template>
  <section>
    <!-- 試聽課程 -->
    <section class="video py-6 mb-6 mb-md-8" data-aos="zoom-in-up">
      <div class="container">
        <h2 class="cs-title my-3 my-md-6" data-style="1">
          <span data-title="試聽課程 Video"></span>
        </h2>
        <ul class="row px-md-6">
          <li class="col-12 col-md-4" v-for="item in data" :key="item.homeLinkId">
            <!-- data-src="https://www.youtube.com/embed/qDO1twqS9eg?rel=0&controls=1&enablejsapi=1&origin=http%3A%2F%2Fkai-tech.com.tw&widgetid=3" -->
            <div
              class="video-btn video__card card card-shadow card-hover-border mb-3 mb-md-0"
              data-toggle="modal"
              data-target="#myModal"
              @click="OpenVideo(item.hasVideo, item.homeLinkUrl, item.homeLinkImgPath)"
            >
              <div class="video__img bg-outer">
                <div
                  class="bg-image"
                  :style="{
                    backgroundImage: 'url(' + item.homeLinkImgPath + ')',
                  }"
                ></div>
              </div>
              <div class="card-body p-3 p-md-4">
                <h5 class="card-title">{{ item.homeLinkName }}</h5>
                <div class="d-flex justify-content-end align-items-center">
                  <p>試聽去</p>
                  <button type="button" class="video-btn btn cs-listen p-2">
                    <i class="fa fa-headphones fa-lg" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="text-center mt-3 mt-md-8">
          <router-link
            class="btn btn-primary
              text-nowrap
              rounded-xl
              btn-hover-accent
              btn-hover-shadow
              btn-hover-focus
              px-4 py-2 px-md-8 py-md-4"
            to="/videos"
          >
            觀看更多 More
          </router-link>
        </div>
      </div>
    </section>
    <!-- videoModal -->
    <div
      class="modal fade bd-example-modal-xl"
      id="videoModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myExtraLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <!-- 16:9 aspect ratio -->
            <div class="embed-responsive embed-responsive-16by9">
              <video id="player" playsinline controls :data-poster="videoImgSrc"></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Plyr from 'plyr';
import Hls from 'hls.js';
import 'plyr/dist/plyr.css';
import { GetHomeVideoTop3 } from '@/api/VideoApi';

/* global $ */
export default {
  props: {
    video: {
      type: Object,
    },
  },
  data() {
    return {
      player: '',
      currentTime: 0,
      videoImgSrc: '',
      videoSource: [
        {
          src: '_720p.m3u8',
          size: 720,
        },
        {
          src: '_1080.m3u8',
          size: 1080,
        },
      ],
      options: {
        clickToPlay: false,
        controls: [
          'play-large',
          'rewind',
          'play',
          'fast-forward',
          'progress',
          'current-time',
          'duration',
          'mute',
          'volume',
          'settings',
          'fullscreen',
        ],
        // 'pip', 子母畫面
        settings: ['quality', 'speed'],
        quality: { default: 720, options: [1080, 720] },
        speed: { selected: 1, options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2] },
      },
      videoSrc: '',
      data: [],
    };
  },
  created() {
    this.GetList();
  },
  mounted() {
    // let $videoSrc;
    // $('.video-btn').click(() => {
    //   $videoSrc = $(this).data('src');
    //   $('#videoModal').modal({
    //     backdrop: 'static',
    //   });
    // }); // when the modal is opened autoplay it
    // $('#videoModal').on('shown.bs.modal', () => {
    //  // set the video src to autoplay and not to show related video.
    //  // Youtube related video is like a box of chocolates... you never know what you're gonna get
    //   $('#video').attr('src', `${$videoSrc}?autoplay=1&amp;modestbranding=1&amp;showinfo=0`);
    // }); // stop playing the youtube video when I close the modal
    // $('#videoModal').on('hide.bs.modal', () => {
    //   // a poor man's stop video
    //   $('#video').attr('src', $videoSrc);
    // }); // video wrapper
    // // poster frame click event
  },
  methods: {
    GetList() {
      GetHomeVideoTop3().then((response) => {
        if (response.data.statusCode === 0) {
          this.data = response.data.data;
          this.data.forEach((item) => {
            // eslint-disable-next-line no-param-reassign
            item.homeLinkUrl = `/freem3u8/${item.homeLinkUrl}/${item.homeLinkUrl}`;
            return item;
          });

          setTimeout(() => {
            this.SetModal();
          }, 100);
        }
      });
    },
    OpenVideo(hasVideo, url, img) {
      if (hasVideo) {
        this.currentTime = 0;
        this.videoImgSrc = img;
        this.videoSource[0].src = `${url}_720.m3u8`;
        this.videoSource[1].src = `${url}_1080.m3u8`;

        const video = document.querySelector('video');

        video.dataset.poster = this.videoImgSrc;
        const defaultOptions = this.options;
        if (Hls.isSupported()) {
          const myStorage = window.localStorage;
          // eslint-disable-next-line operator-linebreak
          const quality = JSON.parse(
            myStorage.getItem('plyr') !== null ? myStorage.getItem('plyr') : '{"quality":720}',
          );
          const Level = parseInt(quality.quality, 10) === 720 ? 0 : 1;
          const hls = new Hls({ startLevel: Level });

          hls.loadSource(`${url}.m3u8`);

          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            const availableQualities = hls.levels.map((l) => l.height);
            defaultOptions.quality = {
              default: 720,
              options: availableQualities,
              forced: true,
              onChange: (e) => this.updateQuality(e),
            };
            this.player = new Plyr(video, defaultOptions);
            hls.attachMedia(video);
            // 影片載入事件
            this.player.on('loadeddata', () => {
              $('.plyr__poster')
                .unbind()
                .on('click', () => {
                  if (this.player.playing === true) this.player.pause();
                  else this.player.play();
                });
            });

            this.player.setXHRWithCredentials(true);
          });

          window.hls = hls;
        } else {
          this.player = new Plyr(video, defaultOptions);
          // 影片載入事件 (event)
          this.player.on('loadeddata', () => {
            if (this.currentTime !== 0) this.player.currentTime = this.currentTime;
            $('.plyr__poster')
              .unbind()
              .on('click', () => {
                if (this.player.playing === true) this.player.pause();
                else this.player.play();
              });
          });
          // 影片時間 (event)
          this.player.on('timeupdate', () => {
            this.currentTime = this.player.currentTime;
          });

          // 影片來源
          this.player.source = {
            type: 'video',
            sources: [
              {
                src: this.videoSource[0].src,
                size: 720,
              },
              {
                src: this.videoSource[1].src,
                size: 1080,
              },
            ],
            poster: this.videoImgSrc,
          };
          // this.player.setXHRWithCredentials(true);
        }

        $('#videoModal').modal({
          backdrop: 'static',
        });
      } else {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '影片即將上架',
        });
      }
    },
    SetModal() {
      $('#videoModal').on('shown.bs.modal', () => {});

      $('#videoModal').on('hide.bs.modal', () => {
        this.player.destroy();
      });
    },
    updateQuality(newQuality) {
      window.hls.levels.forEach((level, levelIndex) => {
        if (level.height === newQuality) {
          window.hls.currentLevel = levelIndex;
        }
      });
    },
  },
};
</script>

<style>
html {
  --plyr-color-main: #0096a4;
}

.embed-responsive:before {
  padding-top: 0% !important;
}
.embed-responsive .plyr__video-wrapper {
  padding-top: 55%;
}

div[role='menu'] {
  max-height: 100px;
  overflow-x: auto;
}
</style>
