<template>
  <!-- 最新消息 -->
  <section class="video pt-6" data-aos="zoom-in-up">
    <div class="container">
      <h2 class="cs-title my-3 my-md-6" data-style="1">
        <span data-title="最新消息 News"></span>
      </h2>
      <!-- 分類導覽 -->
      <aside class="cs-nav" id="filter-bar" data-style="1">
        <ul class="nav__bar d-none d-md-flex">
          <li
            v-for="(item, index) in typeList"
            :key="index"
            :class="{ active: type === item.newsCategoryName }"
            @click="filter(item.newsCategoryName)"
          >
            {{ item.newsCategoryName }}
          </li>
        </ul>
        <div class="dropdown cs-select mb-6 d-md-none" data-style="1">
          <button
            type="button"
            class="btn dropdown-toggle select__btn"
            id="dropdownCourse"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-offset="10,20"
          >
            {{ type }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuOffset">
            <div
              class="dropdown-item select__option"
              v-for="(item, index) in typeList"
              :key="index"
              :class="{ active: type === item.newsCategoryName }"
              @click="filter(item.newsCategoryName)"
            >
              {{ item.newsCategoryName }}
            </div>
          </div>
        </div>
      </aside>
      <ul class="cs-table mb-6" id="filter-list" data-style="8">
        <isotope
          ref="cpt"
          :list="newsList"
          id="root_isotope"
          class="isoDefault"
          :options="getOptions()"
        >
          <li
            class="table__item "
            :class="{ new: isNew(item.newsStartDate) }"
            v-for="item in newsList"
            :key="item.newsId"
          >
            <router-link
              class="text-dark"
              :to="{ name: 'newsDetail', params: { id: item.newsId, fromIndex: true } }"
            >
              <span class="table__item--date">
                {{ item.newsStartDate | toTaipei | moment('YYYYMMDD') }}</span
              >
              <span class="table__item--type"> {{ item.newsCategoryName }} </span>
              <h6 class="table__item--title">{{ item.newsSubject }}</h6>
            </router-link>
          </li>
        </isotope>
      </ul>
      <div class="d-flex justify-content-end">
        <router-link class="text-dark" to="/news">
          更多消息 More
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { GetHomeNews } from '@/api/NewsApi';

export default {
  data() {
    return {
      filterList: [],
      newsList: [],
      data: [],
      typeList: [],
      type: 'all',
    };
  },
  created() {
    this.GetList();
  },
  mounted() {},
  methods: {
    GetList() {
      GetHomeNews({ categoryCount: 4, newsCount: 5 }).then((response) => {
        if (response.data.statusCode === 0) {
          this.data = response.data.data;
          this.typeList = this._.map(this.data, (item) => this._.pick(item, ['newsCategoryName', 'newsCategoryId']));
          const dataMap = this._.map(this.data, 'newsList');

          const flattenDeep = this._.flattenDeep(dataMap);
          this.newsList = this._.uniqBy(flattenDeep, 'newsId');
          const first = this.typeList[0].newsCategoryName;
          this.type = first;
          this.filter(first);
        }
      });
    },
    isNew(time) {
      const NowTime = this.$moment().add(-7, 'd');
      return !this.$moment(time).isBefore(NowTime);
    },
    getOptions() {
      return {
        getSortData: {
          newsStartDate: 'newsStartDate',
        },
        sortBy: 'newsStartDate',
        sortAscending: false,
        getFilterData: {
          filterByType: (el) => this._.indexOf(this.filterList, el.newsId) >= 0,
        },
      };
    },
    filter(key) {
      const data = this._.filter(this.data, ['newsCategoryName', key]);
      this.type = key;
      this.filterList = this._.map(this._.cloneDeep(data[0].newsList), 'newsId');
      this.$refs.cpt.filter('filterByType');
    },
  },
};
</script>

<style></style>
